import { SessionProvider, useSession } from '@services/session';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { hex2hsl, hsl2hex } from 'utils';
import Shades from '../@data/shades.json';
import ARLayout from './layout/ar-viewer';
import * as Views from './views';

export enum VIEWS {
  WELCOME,
  USE_COLOR_CODE,
  CHOOSE_BASE,
  CHOOSE_SHADE,
  CONFIRM,
  END,
}

export type GoToViewFunc = (view: VIEWS) => void;

export type DependSession = {
  s: number;
  b: string;
  c: string;
  v: number;
  l: number;
  cc: string;
  skip: boolean;
};

function App() {
  const { session, updateSession, setError } = useSession<DependSession>();
  const step = parseInt(session.get('s') || '0');
  const base = session.get('b') || Shades.bases[0];
  let shade = session.get('c');
  const lightness = parseInt(session.get('l') || '50');

  if (shade) {
    const hsl = hex2hsl(shade);
    shade = hsl2hex([hsl[0], hsl[1], lightness]);
  }

  const goToView: GoToViewFunc = React.useCallback(
    (view) => {
      updateSession({
        s: view.toString(),
        b: view < VIEWS.CHOOSE_BASE ? null : undefined,
        c: view < VIEWS.CHOOSE_SHADE ? null : undefined,
        v: view < VIEWS.CHOOSE_SHADE ? null : undefined,
        l: view < VIEWS.CHOOSE_SHADE ? null : undefined,
        cc: view < VIEWS.CONFIRM ? null : undefined,
        skip: view < VIEWS.CONFIRM ? null : undefined,
      });
    },
    [updateSession],
  );

  let View: React.ReactElement;
  switch (step) {
    case VIEWS.END:
      View = <Views.End shade={shade || base} goToView={goToView} />;
      break;
    case VIEWS.CONFIRM:
      View = (
        <ARLayout shade={shade || base}>
          <Views.Confirm shade={shade || base} goToView={goToView} />
        </ARLayout>
      );
      break;
    case VIEWS.CHOOSE_SHADE:
      View = (
        <ARLayout shade={shade || base}>
          <Views.ChooseShade base={base} shade={shade} goToView={goToView} />
        </ARLayout>
      );
      break;
    case VIEWS.CHOOSE_BASE:
      View = (
        <ARLayout shade={shade || base}>
          <Views.ChooseBase base={base} goToView={goToView} />
        </ARLayout>
      );
      break;
    case VIEWS.USE_COLOR_CODE:
      View = <Views.UseCode goToView={goToView} />;
      break;
    default:
      View = <Views.Welcome goToView={goToView} />;
      break;
  }

  return View;
}

export default function Main() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <SessionProvider
      defaultState={{ s: '0' }}
      sessionUpdated={(sessionData) => {
        setSearchParams(() => {
          const params = new URLSearchParams();
          for (const [key, value] of sessionData) {
            params.set(key.toString(), value.toString());
          }
          return params;
        });
      }}
    >
      <App />
    </SessionProvider>
  );
}

export { Shades };
