import { Box, Image } from '@chakra-ui/react';
import { Model } from 'models/ar-viewer';
import { hex2grayscale } from 'utils/helpers';

const modelStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  margin: 0,
  position: 'relative',
  left: 0,
  top: 0,
};

const divFilterStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 3,
  top: 0,
  height: '100%',
  backgroundSize: 'inherit',
  backgroundPosition: 'inherit',
  backgroundRepeat: 'inherit',

  WebkitMaskSize: 'contain',
  WebkitMaskPosition: 'center',
  WebkitMaskRepeat: 'no-repeat',
  maskSize: 'contain',
  maskPosition: 'center',
  maskRepeat: 'no-repeat',
  transition: 'left 1s, background-color 0.25s',
};

export function ImageSimulation(props: {
  model: Model;
  dimensions: DOMRectReadOnly;
  color: string;
  positionX: number;
  onLoaded: () => void;
  blendMode: 'color' | 'multiply';
}) {
  const { model, color, dimensions, positionX } = props;
  const imageWidth = 1600 * (dimensions.height / 1200);
  const offset = (dimensions.width - imageWidth) * 0.5 - dimensions.width * positionX;

  return (
    <Box id='model' style={modelStyle}>
      <Box
        id='filter-soft-color'
        backgroundColor={color}
        left={offset}
        pl={dimensions.height}
        minW={imageWidth}
        maxW={imageWidth}
        style={{
          ...divFilterStyle,
          WebkitMaskImage: `url('${model.softColor}')`,
          maskImage: `url('${model.softColor}')`,
          WebkitMaskSize: 'contain',
          mixBlendMode: props.blendMode,
        }}
      />
      <Box
        id='filter-color'
        backgroundColor={color}
        left={offset}
        pl={dimensions.height}
        minW={imageWidth}
        maxW={imageWidth}
        style={{
          ...divFilterStyle,
          WebkitMaskImage: `url('${model.color}')`,
          maskImage: `url('${model.color}')`,
          WebkitMaskSize: 'contain',
        }}
      />
      <Box
        id='filter-correction'
        backgroundColor='#000'
        left={offset}
        pl={dimensions.height}
        minW={imageWidth}
        maxW={imageWidth}
        opacity={255 - hex2grayscale(color) / 255}
        style={{
          ...divFilterStyle,
          WebkitMaskImage: `url('${model.correction}')`,
          maskImage: `url('${model.correction}')`,
          WebkitMaskSize: 'contain',
          mixBlendMode: 'soft-light',
        }}
      />
      <Image
        src={model.background}
        alt='model'
        height='100%'
        position='absolute'
        top='0'
        maxW='unset'
        left={offset}
        transition='left 1s'
        onLoad={props.onLoaded}
      />
    </Box>
  );
}
