import { Box, Image } from '@chakra-ui/react';
import { CustomModel } from 'models/ar-viewer';
import React from 'react';
import { useResizeObserver } from 'utils/hooks';

const modelStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  margin: 0,
  position: 'relative',
  left: 0,
  top: 0,
};

const imgFilterStyle: React.CSSProperties = {
  maxWidth: 'none',
  height: '100%',
  position: 'absolute',

  top: 0,
  zIndex: 1,
  transition: 'left 0.3s',
};

export function LandmarkSimulation(props: {
  model: CustomModel;
  dimensions: DOMRectReadOnly;
  color: string;
  positionX: number;
  onLoaded: () => void;
  blendMode: 'color' | 'multiply';
}) {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const [image, imgDimensions] = useResizeObserver();

  const { model, color, dimensions } = props;
  const offset = (dimensions.width - (imgDimensions?.width || 2000)) * 0.5;

  React.useEffect(() => {
    if (!canvas.current) return;
    const ctx = canvas.current.getContext('2d');
    if (!ctx) return;

    ctx.canvas.width = imgDimensions?.width || 1000;
    ctx.canvas.height = imgDimensions?.height || 1600;

    let ratio = 1.5;
    let img = document.getElementById('custom-model') as HTMLImageElement | null;
    if (img) {
      ratio = ctx.canvas.width / img.naturalWidth;
    }

    const imageIdArr = {
      'lips-img1': color,
      'lips-img2': color,
      'lips-img3': 'rgba(0,0,0,0.75)',
    };

    Object.entries(imageIdArr).forEach(([id, color]) => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.fillStyle = color;
      ctx.beginPath();
      model.landmarks.topLip.forEach((point, i) => {
        if (i === 0) return ctx.moveTo(point.x * ratio, point.y * ratio);
        ctx.lineTo(point.x * ratio, point.y * ratio);
      });
      model.landmarks.bottomLip.forEach((point, i) => {
        if (i === 0) return ctx.moveTo(point.x * ratio, point.y * ratio);
        ctx.lineTo(point.x * ratio, point.y * ratio);
      });
      ctx.fill();

      let myImage = ctx.canvas.toDataURL('image/png');
      let imageElement = document.getElementById(id) as HTMLImageElement | null;
      if (imageElement) imageElement.src = myImage;
    });

    props.onLoaded();
  }, [canvas, model, color, imgDimensions?.height, imgDimensions?.width]);

  return (
    <Box id='model' style={modelStyle}>
      <canvas
        ref={canvas}
        style={{
          height: '100%',
          position: 'absolute',
          display: 'block',
          top: 0,
          left: 0,
          opacity: 0,
        }}
      />
      <Image
        id='lips-img1'
        src={model.image as string}
        alt='filter'
        style={{
          ...imgFilterStyle,
          left: offset,
          filter: `blur(2px)`,
          mixBlendMode: props.blendMode,
          opacity: 0.72,
        }}
      />
      <Image
        id='lips-img2'
        src={model.image as string}
        alt='filter'
        style={{
          ...imgFilterStyle,
          left: offset,
          filter: `blur(5px)`,
          mixBlendMode: 'normal',
          opacity: 0.32,
        }}
      />
      <Image
        id='lips-img3'
        src={model.image as string}
        alt='filter'
        style={{
          ...imgFilterStyle,
          left: offset,
          filter: `blur(1px)`,
          mixBlendMode: 'soft-light',
          opacity: 0.2,
        }}
      />
      <Image
        id='custom-model'
        src={model.image as string}
        alt='model'
        style={{
          height: '100%',
          position: 'absolute',
          top: 0,
          maxWidth: 'none',
          transition: 'left 0.3s',
          left: offset,
        }}
        ref={image}
      />
    </Box>
  );
}
