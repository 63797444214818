import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDebouncedEffect } from 'utils/hooks';

export type UpdateSessionFunc<SessionData> = (update: Partial<Record<keyof SessionData, any>>) => void;

export type SessionState<SessionData> = {
  session: Map<keyof SessionData, string>;
  /**
   * Updates the `session`-keys to the specified values defined in `update`.
   * In case a value is set to `null`, it will be deleted from the `session`.
   */
  updateSession: UpdateSessionFunc<SessionData>;
  updateShade: (hex: string) => void;
  getFromSession: <K extends keyof SessionData>(key: K) => SessionData[K];
  setError: (message: string) => void;
  resetSession: () => void;
};

const SessionContext = React.createContext<SessionState<unknown>>(undefined!);

export function SessionProvider<SessionData>({
  children,
  savedState,
  defaultState,
  sessionUpdated,
}: {
  children: React.ReactNode;
  savedState?: Partial<SessionData>;
  defaultState: SessionData;
  sessionUpdated: (sessionData: Map<keyof SessionData, any>) => void;
}) {
  const [sessionData, setSessionData] = React.useState<Map<keyof SessionData, any>>(new Map());

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null!);
  const [message, setMessage] = React.useState('');

  const updateSession: UpdateSessionFunc<SessionData> = React.useCallback((update) => {
    setSessionData((session) => {
      for (const [key, value] of Object.entries(update) as [keyof SessionData, any][]) {
        if (value === undefined) continue;
        else if (value === null) session.delete(key);
        else session.set(key, value);
      }
      return new Map(session);
    });
  }, []);

  const updateShade = React.useCallback((hex: string) => {
    setSessionData((session) => {
      session.set('c' as keyof SessionData, hex);
      return new Map(session);
    });
  }, []);

  const getFromSession = React.useCallback(
    <K extends keyof SessionData>(key: K) => {
      return sessionData.get(key) || (defaultState[key] as SessionData[K]);
    },
    [sessionData],
  );

  const setError = React.useCallback((message: string) => {
    setMessage(message);
    onOpen();
  }, []);

  const resetSession = React.useCallback(() => {
    setSessionData(() => {
      return Object.entries(defaultState as Record<string, any>).reduce((map, [key, value]) => {
        map.set(key as keyof SessionData, value);
        return map;
      }, new Map<keyof SessionData, any>());
    });
  }, [defaultState]);

  useDebouncedEffect(
    () => {
      sessionUpdated(sessionData);
    },
    250,
    [sessionUpdated, sessionData],
  );

  React.useEffect(() => {
    if (savedState) {
      setSessionData(() => {
        return Object.entries({ ...defaultState, ...savedState }).reduce((map, [key, value]) => {
          if (value) map.set(key as keyof SessionData, value);
          return map;
        }, new Map<keyof SessionData, any>());
      });
    }
  }, [savedState]);

  return (
    <SessionContext.Provider
      value={{
        session: sessionData as any,
        updateSession,
        updateShade,
        getFromSession,
        setError,
        resetSession,
      }}
    >
      <ErrorBoundary
        fallback={<div>App crashed</div>}
        onError={(e) => {
          setMessage(e.message);
          onOpen();
        }}
      >
        {children}
      </ErrorBoundary>
      <AlertDialog motionPreset='scale' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Oh no, something went wrong :(</AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </SessionContext.Provider>
  );
}

export function useSession<SessionData>(): SessionState<SessionData> {
  const context = React.useContext(SessionContext) as SessionState<SessionData>;
  if (context === undefined) throw new Error('useSession must be used within a Session provider');
  return context;
}
