import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { deg2rad, getComputedCssProperty, HSL, hsl2hex, rad2deg } from 'utils';
import { BoundingRect, useBoundingRect } from 'utils/hooks';

function calculateRotationFromPoint(x: number, y: number, x0: number, y0: number) {
  return -rad2deg(Math.atan2(y - y0, x - x0));
}

function calculatePositionFromRotation(x0: number, y0: number, r: number, deg: number): [x: number, y: number] {
  const angleRadians = -deg2rad(deg);
  const x = x0 + r * Math.cos(angleRadians);
  const y = y0 + r * Math.sin(angleRadians);
  return [x, y];
}

function getRotationFromPointerEvent(e: MouseEvent | TouchEvent, containerRect: BoundingRect) {
  const client = e instanceof TouchEvent ? e.touches[0] : e;
  let rotation = calculateRotationFromPoint(
    client.clientX - containerRect.left,
    client.clientY - containerRect.top,
    containerRect.width / 2,
    containerRect.height / 2,
  );

  rotation -= 90;
  if (rotation < 0) rotation += 360;
  else if (rotation > 360) rotation -= 360;
  return rotation;
}

function drawHueCircle(hsl: HSL, canvas: HTMLCanvasElement, scale: number) {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const colors: string[] = [];
  for (let i = 0; i < 361; i += 5) colors.push(hsl2hex([i, hsl[1], hsl[2]]));
  colors.reverse();

  const x0 = 150 * scale,
    y0 = 150 * scale,
    r = 130 * scale;

  ctx.beginPath();
  ctx.arc(x0, y0, r, 0, Math.PI * 2);
  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.closePath();

  let start: number = 0,
    gradient: CanvasGradient,
    startColor: string,
    endColor: string,
    partLength = (2 * Math.PI) / colors.length;

  for (let i = 0; i < colors.length; i++) {
    startColor = colors[i];
    endColor = colors[(i + 1) % colors.length];

    // x start / end of the next arc to draw
    const xStart = x0 + Math.cos(start) * r;
    const xEnd = x0 + Math.cos(start + partLength) * r;
    // y start / end of the next arc to draw
    const yStart = y0 + Math.sin(start) * r;
    const yEnd = y0 + Math.sin(start + partLength) * r;

    ctx.beginPath();

    gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1.0, endColor);

    ctx.strokeStyle = gradient;
    ctx.arc(x0, y0, r, start, start + partLength + 0.01);
    ctx.lineWidth = 40 * scale;
    ctx.stroke();
    ctx.closePath();

    start += partLength;
  }
}

export function ColorSliderCircularInput(props: { hsl: HSL; onChange: (hsl: HSL) => void; style?: React.CSSProperties }) {
  const [container, containerRect] = useBoundingRect<HTMLDivElement>();
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const scale = 4;

  const hslRef = React.useRef(props.hsl);
  const [position, setPosition] = React.useState<[x: number, y: number]>([0, 0]);

  const onMoveDrag = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      props.onChange([Math.round(getRotationFromPointerEvent(e, containerRect)), hslRef.current[1], hslRef.current[2]]);
    },
    [containerRect],
  );
  const onStartDrag = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      onMoveDrag(e);
      window.addEventListener('touchmove', onMoveDrag);
      window.addEventListener('mousemove', onMoveDrag);
    },
    [onMoveDrag],
  );
  const onStopDrag = React.useCallback(() => {
    window.removeEventListener('touchmove', onMoveDrag);
    window.removeEventListener('mousemove', onMoveDrag);
  }, [onMoveDrag]);

  /** Set slider thumb at the right position based on prop value */
  React.useEffect(() => {
    const top = parseFloat(getComputedCssProperty('center', 'top') || '0px');
    const position = calculatePositionFromRotation(
      parseFloat(getComputedCssProperty('center', 'left') || '0px'),
      top,
      top * 0.87,
      props.hsl[0] + 90,
    );
    setPosition(position);
  }, [props.hsl[0], containerRect]);

  /** Draw color circle based on current HSL values. */
  React.useEffect(() => {
    if (canvas.current) {
      drawHueCircle(props.hsl, canvas.current, scale);
    }
    hslRef.current = props.hsl;
  }, [props.hsl[1], props.hsl[2], scale]);

  /** Attach all listeners  */
  React.useEffect(() => {
    if (!container.current) return;
    container.current.addEventListener('touchstart', onStartDrag);
    container.current.addEventListener('mousedown', onStartDrag);
    window.addEventListener('touchend', onStopDrag);
    window.addEventListener('mouseup', onStopDrag);

    return () => {
      if (!container.current) return;
      container.current.removeEventListener('touchstart', onStartDrag);
      container.current.removeEventListener('mousedown', onStartDrag);
      window.removeEventListener('touchend', onStopDrag);
      window.removeEventListener('mouseup', onStopDrag);
    };
  }, [onStartDrag, onStopDrag]);

  return (
    <Flex
      ref={container}
      aria-label='colour-picker'
      style={props.style}
      cursor='pointer'
      w='100%'
      h={{ base: '100%', web: 'auto' }}
      bottom={{ base: '0', web: 'unset' }}
      position='relative'
      justifyContent={'center'}
      my='5vh'
      _after={{
        content: `"Hue"`,
        fontSize: `${0.07 * containerRect.width}px`,
        position: 'absolute',
        width: '33%',
        top: '50%',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        pointerEvents: 'none',
      }}
    >
      <canvas
        aria-label='color-space'
        ref={canvas}
        width={300 * scale}
        height={300 * scale}
        style={{
          maxWidth: 'Min(500px, 25vw)',
          width: '100%',
          height: '100%',
          transform: 'rotate(-90deg)',
        }}
      />
      <Box
        id='center'
        position='absolute'
        top='50%'
        left='50%'
        width='5px'
        height='5px'
        backgroundColor='#f0f'
        borderRadius='50%'
        transform='translate(-50%, -50%)'
        visibility={'hidden'}
      />
      <Box
        aria-label='selection-knob'
        userSelect={'none'}
        position={'absolute'}
        display={'block'}
        borderRadius={'50%'}
        border={'4px solid #f2f2f2'}
        boxShadow={'0px 2px 5px 2px rgba(0, 0, 0, 0.2)'}
        transform={'translateY(-50%) translateX(-50%)'}
        transitionProperty={'transform'}
        transitionDuration={'normal'}
        style={{
          backgroundColor: hsl2hex(props.hsl),
          left: `${position[0]}px`,
          top: `${position[1]}px`,
          height: `${Math.min(60, 0.15 * containerRect.width)}px`,
          width: `${Math.min(60, 0.15 * containerRect.width)}px`,
        }}
        _active={{
          transform: 'translateY(-50%) translateX(-50%) scale(1.15)',
        }}
      />
    </Flex>
  );
}
