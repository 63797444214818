import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { api } from '@services/api';
import { ReactComponent as ExpressionIcon } from 'assets/media/guidelines/face-expression.svg';
import { ReactComponent as LightningIcon } from 'assets/media/guidelines/lighting.svg';
import { ReactComponent as LipstickIcon } from 'assets/media/guidelines/lipstick.svg';
import { ReactComponent as PositionIcon } from 'assets/media/guidelines/positioning.svg';
import { AxiosError } from 'axios';
import { readAndCompressImage } from 'browser-image-resizer';
import { Loader } from 'components';
import { CustomModel, Model } from 'models/ar-viewer';
import React from 'react';
import { RiCamera3Line, RiUserStarLine } from 'react-icons/ri';
import { sleep, useMediaQuery, useResizeObserver } from 'utils';
import { ImageSimulation } from './imageSimulation';
import { LandmarkSimulation } from './landmarkSimulation';
import './viewer.css';

class ViewerError extends Error {
  constructor(message: string) {
    super();
    this.message = message;
  }
}

export function Viewer(props: {
  models: { [skinTone: string]: Model };
  savedModel?: CustomModel;
  blendMode: 'color' | 'multiply';
  color: string;
  onUseCustomModel?: (customModel: CustomModel | null) => void;
  onError: (message?: string) => void;
  breakpoint?: string;
  allowCustomModel?: boolean;
}) {
  const isLarge = useMediaQuery(props.breakpoint || '750px');
  const [viewer, viewDimensions] = useResizeObserver();
  const [isLoading, setLoading] = React.useState(true);
  const [isUploading, setUploading] = React.useState(false);
  const [skinTone, setSkintone] = React.useState(Object.keys(props.models)[0]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageInput = React.useRef<HTMLInputElement>(null!);
  const [customModel, setCustomModel] = React.useState<CustomModel | null>(null);

  React.useEffect(() => {
    setLoading(true);
  }, [skinTone]);

  React.useEffect(() => {
    if (!imageInput.current) return;

    const onChange = async () => {
      await sleep(500);
      try {
        const input = imageInput.current;
        if (input.files && input.files[0]) {
          const image = await readAndCompressImage(input.files[0], {
            quality: 0.5,
            maxHeight: 1600,
            maxWidth: 3000,
          });

          const landmarks = await api.recommendation.getLandMarks({
            image,
            imageName: input.files[0].name,
          });

          if (landmarks.bottomLip.length === 0 || landmarks.topLip.length === 0)
            throw new ViewerError('No face detected on selected photo. Please, check if the photo meets the guidelines and try it again.');

          var reader = new FileReader();
          reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
            if (!e.target?.result) throw new ViewerError('Selected photo could not be loaded.');
            const customModel = {
              image: e.target.result,
              landmarks,
            };
            setCustomModel(customModel);
            if (props.onUseCustomModel) props.onUseCustomModel(customModel);
            setUploading(false);
          });
          reader.readAsDataURL(image);
        }
      } catch (e) {
        console.log(e);
        if (e instanceof ViewerError) props.onError(e.message);
        else if (e instanceof AxiosError)
          if (e.request.status === 415)
            props.onError('Photo format is not allowed or could not be determined, please try another picture.');
          else props.onError('Something went wrong on our end to analyze the photo, please try again later.');
        else if (e instanceof Error) props.onError(e.message);
        else props.onError();
      }

      setUploading(false);
    };

    if (isUploading) {
      window.addEventListener('change', onChange);

      return () => {
        window.removeEventListener('change', onChange);
      };
    }
  }, [imageInput, isUploading, props.onUseCustomModel]);

  React.useEffect(() => {
    if (props.savedModel) setCustomModel(props.savedModel);
  }, [props.savedModel, setCustomModel]);

  return (
    <>
      <input ref={imageInput} id='face-image' type='file' hidden accept='image/*' />
      <Box ref={viewer} position='relative' height='100%' width='100%' overflow='hidden'>
        <ButtonGroup position='absolute' left='0' top='0' p={3} zIndex={11}>
          {props.allowCustomModel && (
            <IconButton
              onClick={onOpen}
              size={isLarge ? 'lg' : 'sm'}
              aria-label='Upload photo'
              title='upload photo'
              icon={<RiCamera3Line />}
              isRound={true}
              variant='solid'
            />
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<RiUserStarLine />}
              size={isLarge ? 'lg' : 'sm'}
              title='Select model'
              aria-label='Select model'
              isRound={true}
              variant='solid'
            />
            <MenuList backgroundColor='unset' border='unset' pt={0} pb={0} boxShadow='0'>
              {Object.keys(props.models).map((tone, i) => (
                <MenuItem
                  key={i}
                  backgroundColor={tone}
                  mb={isLarge ? '8px' : '2px'}
                  borderRadius='full'
                  w={isLarge ? '48px' : '32px'}
                  h={isLarge ? '48px' : '32px'}
                  border={tone === skinTone ? '5px solid rgb(242, 242, 242)' : 'none'}
                  onClick={() => {
                    setCustomModel(null);
                    if (props.onUseCustomModel) props.onUseCustomModel(null);
                    setSkintone(tone);
                  }}
                />
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
        <Loader isLoading={isLoading || isUploading} style={{ position: 'relative', height: '100%', width: '100%' }}>
          {viewDimensions && customModel && (
            <LandmarkSimulation
              color={props.color}
              blendMode={props.blendMode}
              positionX={0.25 * 0}
              dimensions={viewDimensions}
              model={customModel}
              onLoaded={() => setLoading(false)}
            />
          )}
          {viewDimensions && !customModel && (
            <ImageSimulation
              color={props.color}
              blendMode={props.blendMode}
              positionX={0.25 * 0}
              dimensions={viewDimensions}
              model={props.models[skinTone]}
              onLoaded={() => setLoading(false)}
            />
          )}
        </Loader>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered={isLarge ? true : false}
          size={isLarge ? 'lg' : 'full'}
          scrollBehavior='inside'
          motionPreset='scale'
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: 'auto', web: '600px' }}>
            <ModalHeader>Picture guidelines</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>For the best results and no unwanted errors, make sure your image meets the following guidelines:</Text>
              <Grid templateAreas={`"guide1 guide2" "guide3 guide4"`} templateColumns={'1fr 1fr'} templateRows={'1fr 1fr'} pt={4} gap={4}>
                <GridItem as={Flex} area='guide1' flexDir='column'>
                  <LightningIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NATURAL LIGHTING
                  </Heading>
                  <Text fontSize='1.3rem'>
                    Make sure that the lightning is natural as it could make the color appear differently than in real life.
                  </Text>
                </GridItem>
                <GridItem as={Flex} area='guide2' flexDir='column'>
                  <PositionIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    FACE CENTERED
                  </Heading>
                  <Text fontSize='1.3rem'>
                    Make sure that your face is properly centered and at a good distance from the camera (about 30cm).
                  </Text>
                </GridItem>
                <GridItem as={Flex} area='guide3' flexDir='column'>
                  <ExpressionIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NATURAL FACE EXPRESSION
                  </Heading>
                  <Text fontSize='1.3rem'>The AR is not perfect, for a good preview take a picture with a natural face expression.</Text>
                </GridItem>
                <GridItem as={Flex} area='guide4' flexDir='column'>
                  <LipstickIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NO LIPSTICK
                  </Heading>
                  <Text fontSize='1.3rem'>Do not wear lipstick as this can influence the shades that you will see.</Text>
                </GridItem>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button onClick={onClose} variant='ghost'>
                  Close
                </Button>
                <Button
                  colorScheme='blue'
                  onClick={() => {
                    imageInput.current.click();
                    setUploading(true);
                    onClose();
                  }}
                >
                  Upload picture
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
